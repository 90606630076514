<template>
    <div class="gameDetail">
        <!-- banner -->
        <picture class="banner">
            <source srcset="../../assets/imgs/h5_images/h5_game_detail_penta5_regular.jpg" media="(max-width: 414px)">
            <img src="../../assets/imgs/gameBanner/game_detail_penta5_regular.jpg">
        </picture>
        <!--games -->
        <main>
            <div class="content">
                <div class="left"><img src="../../assets/imgs/gameIcon/penta_5_regular.png"></div>
                <div class="right">
                    <div class="title">
                        <div>PENTA 5</div>
                        <div class="tagWraper">
                            <div class="tag"><img src="../../assets/imgs/tagIcon/regular.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/regular_3m.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/regular_99rtp.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/regular_bmm.png"></div>
                            <div class="logoOld"><img src="../../assets/imgs/maxgate_old_icon/Penta5_Regular.png"></div>
                        </div>
                    </div>
                    <div class="introduce pc">
                        Penta 5 games are RNG games that come up with 5 balls between 0-9. The number of the ball can be repeated. Players are allowed to select whether the number on the specific ball is an odd number or even number. After 3 minuntes 5 numbers are randomly generated. If the number of the specific ball matches the players’ selection they win.
                    </div>
                    <a class="playBtn" href="https://demo.maxgatesoftware.com/#/Penta5/Regular">Play Demo</a>
                </div>
            </div> 
            <div class="introduce mobile">
                Penta 5 games are RNG games that come up with 5 balls between 0-9. The number of the ball can be repeated. Players are allowed to select whether the number on the specific ball is an odd number or even number. After 3 minuntes 5 numbers are randomly generated. If the number of the specific ball matches the players’ selection they win.
            </div>
            <div class="picWrapper pc">
                <div class="pic"><img src="../../assets/imgs/gamedetail/regular_1.jpg"></div>
                <div class="pic"><img src="../../assets/imgs/gamedetail/regular_2.jpg"></div> 
                <div class="pic"><img src="../../assets/imgs/gamedetail/regular_3.jpg"></div>  
            </div>
            <div class="picWrapper mobile">
                <div class="pic"><img src="../../assets/imgs/h5_images/h5_screenshot_regular.jpg"></div>
            </div>
        </main>
        <!--infor -->
        <section style="background:rgb(246, 246, 247);box-shadow: 2px 2px 3px rgba(161,161,161,0.5) inset">
            <div class="infor">
                <div class="left">
                    <div class="subject">INTRODUCTION</div>
                    <div class="features">
                        <div class="title">How To Play</div>
                        <div class="desc">
                            <p>To play Penta 5, firstly you might select the 1st ball, the 2nd ball, the 3rd ball, the 4th ball or the 5th ball.</p>
                            <p>You can place your bet whether the number of the chosen ball is Odd or Even by selecting the corresponding button.</p>
                            <p>Number 0 counted as an even number.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Placing Bets</div>
                        <div class="desc">
                            <p>Penta 5 allows you to place your stake GBP 1, 5, 10, 50 by clicking different chip icon.</p>
                            <p>The amount of your stake will be shown in the field.</p>
                            <p>You might also enter the amount of your bet in the field.</p>
                            <p>Select the “Bet” button to confirm your bet.</p>
                            <p>You will see your transaction record on “Your Recent Betslip” once your bet is confirmed.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Getting Started</div>
                        <div class="desc">
                            <p>Penta 5 Regular starts every 3 minutes.</p>
                            <p>The timer shows the remaining time for the next game.</p>
                            <p>The draw result is shown on the tab.</p>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="subject">GAME SPECIFICATIONS</div>
                    <div class="features">
                        <div class="title">Supported Platform</div>
                        <div class="desc">
                            <p>Desktop, Mobile.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Game Logic</div>
                        <div class="desc">
                            <p>Draw 5 balls between 0-9, the number can be repeated.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">RTP</div>
                        <div class="desc">
                            <p>The return to player percentage (RTP%) for this game is 99.00%.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Payouts</div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Bet Type</th>
                                    <th>Selection</th>
                                    <th>Odds</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Regular Draw</td>
                                    <td>Over 4.5</td>
                                    <td>1.98</td>
                                </tr>
                                <tr>
                                    <td>Regular Draw</td>
                                    <td>Under 4.5</td>
                                    <td>1.98</td>
                                </tr>
                                 <tr>
                                    <td>Regular Draw</td>
                                    <td>Odd</td>
                                    <td>1.98</td>
                                </tr>
                                 <tr>
                                    <td>Regular Draw</td>
                                    <td>Even</td>
                                    <td>1.98</td>
                                </tr>
                                <tr>
                                    <td>Draw Sum</td>
                                    <td>Over 22.5</td>
                                    <td>1.98</td>
                                </tr>
                                <tr>
                                    <td>Draw Sum</td>
                                    <td>Under 22.5</td>
                                    <td>1.98</td>
                                </tr>
                                <tr>
                                    <td>Draw Sum</td>
                                    <td>Odd</td>
                                    <td>1.98</td>
                                </tr>
                                <tr>
                                    <td>Draw Sum</td>
                                    <td>Even</td>
                                    <td>1.98</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <!--history -->
        <PeriodHistory lotteryCode="Penta5"/>
        <!--more game -->
        <div class="moreGame">
            <h1>MORE GAMES</h1>
            <div class="gameWrapper">
                <a href="/#/games/Gamma3_Regular">
                    <div class="imgBox"><img src="../../assets/imgs/gameIcon/gamma_3_regular.png"></div>
                </a>
                <a href="/#/games/Penta5_Classic">
                <div class="imgBox"><img src="../../assets/imgs/gameIcon/penta_5_classic.png"></div>
                </a>
                <a href="/#/games/Lucky7_Daily">
                <div class="imgBox"><img src="../../assets/imgs/gameIcon/lucky_7_daily.png"></div>
                </a>
                <a href="/#/games/Rolling10_Regular">
                <div class="imgBox"><img src="../../assets/imgs/gameIcon/rolling_10_regular.png"></div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import DatePicker from 'vue2-datepicker';
import PeriodHistory from '@/components/PeriodHistory';
import 'vue2-datepicker/index.css';
import lib from '@/lib';
export default {
    components: { DatePicker,PeriodHistory },
}
</script>
